import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import UnderMaintenance from "./underMaintenance"
import "./assets/scss/comman.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
const isUnderMaintenance = true
root.render(
  <React.StrictMode>
    
      <BrowserRouter>
      <App />
    </BrowserRouter>

  </React.StrictMode>
);
