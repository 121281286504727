import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import palette from "../../../theme/palette";
import CommonTypo from "../../../components/CommonTypo";
import { Box } from "@mui/material";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    tableClass: {
        display: "block",
        width: "auto",
        height: "calc(100vh - 372px)",
        overflow: "auto",
    },
    stickeyClass: {
        position: "sticky",
        top: "0",
        left: "0",
        zIndex: 5,
        background: "var(--white-background)",
    },
    row: {},
    headerSwitch: {
        color: palette.common.black,
        minWidth: "30vw",
        maxWidth: "30vw",
    },
    firstColumn: {
        width: "300px",
        // whiteSpace: 'nowrap',       // Ensure text stays on one line
        // overflow: 'hidden',         // Hide overflowed content
        // textOverflow: 'ellipsis',   // Show ellipsis for overflowed text
        // lineClamp: 1,
    },
    titleRow: {
        height: "24px"
    },
    detailsRow: {
        height: "80px",
        borderBottom: "1px solid #EEEEEE"
    },
    detailsWidth: {
        width: "100px",
        whiteSpace: 'nowrap',
    }
}))
const AreasTable = ({ areaData, selectedRange, loadingResponse }) => {
    const classes = useStyles();
    const [dates, setDates] = useState([])
    useEffect(() => {
        setDates([])
    }, [selectedRange])
    useEffect(() => {
        setDates([])
        if (!loadingResponse) {
            const statisDa = []
            setDates(statisDa)
            if (areaData[0] && areaData[0].alls && areaData[0].alls[0] && areaData[0].alls[0].influenceResponses) {
                for (let index = 0; index < (areaData[0].alls[0].influenceResponses || []).length; index++) {
                    const element = (areaData[0].alls[0].influenceResponses || [])[index];
                    if (selectedRange === "CURRENT_QUARTER") {
                        statisDa.push({
                            date: "Week " + element.week,
                            // month: moment(element?.date).format("MMM"),
                            value: element.avg
                        })
                    } else if (selectedRange === "CURRENT_YEAR") {
                        statisDa.push({
                            date: element.monthName && element.monthName.slice(0, 3),
                            // month: moment(element?.date).format("MMM"),
                            value: element.avg
                        })
                    } else {
                        if (element && !element.uid) {
                            statisDa.push({
                                date: moment(element?.date).format("DD"),
                                month: moment(element?.date).format("MMM"),
                                year: moment(element?.date).format("YYYY"),
                                value: element.index
                            })
                        } else {
                            statisDa.push({
                                date: moment(element?.date).format("DD"),
                                month: moment(element?.date).format("MMM"),
                                year: moment(element?.date).format("YYYY"),
                                value: "NaN"
                            })
                        }
                    }

                }
            } else if (areaData[0] && areaData[0].alls && areaData[0].alls[0] && areaData[0].alls[0].outcomesResponses) {
                for (let index = 0; index < (areaData[0].alls[0].outcomesResponses || []).length; index++) {
                    const element = (areaData[0].alls[0].outcomesResponses || [])[index];
                    if (selectedRange === "CURRENT_QUARTER") {
                        statisDa.push({
                            date: "Week " + element.week,
                            // month: moment(element?.date).format("MMM"),
                            value: element.avg
                        })
                    } else if (selectedRange === "CURRENT_YEAR") {
                        statisDa.push({
                            date: element.monthName && element.monthName.slice(0, 3),
                            // month: moment(element?.date).format("MMM"),
                            value: element.avg
                        })
                    } else {
                        if (element && !element.uid) {
                            statisDa.push({
                                date: moment(element?.date).format("DD"),
                                month: moment(element?.date).format("MMM"),
                                year: moment(element?.date).format("YYYY"),
                                value: element.index
                            })
                        } else {
                            statisDa.push({
                                date: moment(element?.date).format("DD"),
                                month: moment(element?.date).format("MMM"),
                                year: moment(element?.date).format("YYYY"),
                                value: "NaN"
                            })
                        }
                    }

                }
            }

            setDates(statisDa)
        }

    }, [areaData, selectedRange, loadingResponse])


    return <div
        style={{
            width: "100%",
            height: "auto",
        }}
    >
        <table
            className={classes.tableClass}
            border={0}
            cellPadding={9}
            cellSpacing="0px"
            id="main-table"
        >
            <thead>
                <tr className={clsx(classes.row, classes.stickeyClass)}>

                    <th className={classes.firstColumn}></th>
                    {[...dates].map(it => <th key={it.date} className={classes.detailsWidth}><CommonTypo >{it.date}</CommonTypo></th>)}
                </tr>
            </thead>
            <tbody>
                {areaData.map((it, index) => {
                    return <>
                        {index > 0 &&  <tr className={classes.titleRow} style={{
                                height: "32px"
                            }}>
                            <td colSpan={dates.length + 1} className={classes.firstColumn} >
                                <span style={{
                                height: "32px"
                            }}>

                                </span>
                            </td>
                        </tr>}
                        <tr className={classes.titleRow}>
                            <td /* colSpan={dates.length + 1} */ className={classes.firstColumn}>
                                <CommonTypo color="var(--color-text-primary)" size={24} lineHeight={36} fontWeight={700} title={it.name}
                                extraSx={{
                                    display: "block",
                                    width: "300px",
                                    whiteSpace: 'nowrap',       // Ensure text stays on one line
                                    overflow: 'hidden',         // Hide overflowed content
                                    textOverflow: 'ellipsis',   // Show ellipsis for overflowed text
                                    lineClamp: 1,
                                }}>{it.name}</CommonTypo>
                            </td>
                        </tr>
                        {it.alls.map(ite => {
                            return <tr className={classes.detailsRow} >
                                <td className={clsx(classes.firstColumn, classes.detailsRow)}>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px",
                                        width: "264px"
                                    }}>
                                        <CommonTypo color="var(--color-text-primary)" size={16} lineHeight={16} fontWeight={600}>{ite.name}</CommonTypo>
                                        <Box sx={{
                                            display: "flex",
                                            gap: "8px",
                                            alignItems: "center"
                                        }}>
                                            <CommonTypo color="var(--color-text-primary)" size={12} lineHeight={12} fontWeight={400}>{ite.influenceId ? "Influence" : "Outcome"}</CommonTypo>
                                            <CommonTypo color="var(--color-text-primary)" size={12} lineHeight={12} fontWeight={400}>{"•"}</CommonTypo>
                                            <CommonTypo color="var(--color-text-primary)" size={12} lineHeight={12} fontWeight={400}>
                                                {ite.outcomeId || ite.type === "TYPE2" ? "1-5 Scale" : ite.type === "TYPE12" ? "Custom scale" : "Binary Scale"}
                                            </CommonTypo>

                                        </Box>
                                    </Box>
                                </td>
                                {dates.map(item => {

                                    return <td key={ite.date} className={classes.detailsWidth} style={{
                                        borderBottom: "1px solid #EEEEEE"
                                    }}>
                                        <BarRender
                                            selectedRange={selectedRange}
                                            ite={ite}
                                            item={item}
                                            mainKey={ite.date + "_" + (ite.influenceId || ite.outcomeId)}
                                            isDot={ite.type === "TYPE3"}
                                        />
                                    </td>
                                })}
                            </tr>
                        })}
                    </>
                })}
            </tbody>
        </table>
    </div>
}

export default AreasTable

const BarRender = ({
    total = 1,
    isDot,
    mainKey,
    item,
    ite,
    selectedRange
}) => {
    const [max, setmax] = useState(0)
    const [value, setValue] = useState(0)
    const [found, setFound] = useState(null)
    
    useEffect(() => {
        let foundPre = null
        if(ite.influenceId === "vTkNdfUbhZTH986CZed9qf") {
            console.log("influence ite.influenceResponses", ite.outcomesResponses)
        }
        if (ite.outcomeId && ite.outcomesResponses) {
            foundPre = ite.outcomesResponses.find(it1 => {
                let date = null
                let moneth = null
                let year = null
                if (selectedRange === "CURRENT_QUARTER") {
                    date = it1.week
                    return date === Number(item.date.split("Week ")[1])
                } else if (selectedRange === "CURRENT_YEAR" && it1.monthName) {
                    date = it1.monthName
                    return date.slice(0, 3) === item.date
                } else {
                    date = moment(it1?.date).format("DD")
                    moneth = moment(it1?.date).format("MMM")
                    year = moment(it1?.date).format("YYYY")
                }
                if(selectedRange === "CUSTOM") {
                    return date === item.date && moneth === item.month && year === item.year
                }
                return date === item.date
            })
        } else if (ite.influenceResponses) {
            foundPre = ite.influenceResponses.find(it1 => {
                let date = null
                let moneth = null
                let year = null
                if (selectedRange === "CURRENT_QUARTER") {
                    date = it1.week
                    return date === Number(item.date.split("Week ")[1])
                } else if (selectedRange === "CURRENT_YEAR" && it1.monthName) {
                    date = it1.monthName
                    return date.slice(0, 3) === item.date
                } else {
                    date = moment(it1?.date).format("DD")
                    moneth = moment(it1?.date).format("MMM")
                    year = moment(it1?.date).format("YYYY")
                }
                if(selectedRange === "CUSTOM") {
                    return date === item.date && moneth === item.month && year === item.year
                }
                return date === item.date
                
            })
        }
        if(ite.influenceId === "vTkNdfUbhZTH986CZed9qf") {
            console.log("foundPre max ?????----", ite.outcomesResponses, ite.influenceResponses, foundPre, item);
        }
        if(foundPre) {
            if(foundPre.uid === "NO_DATA") {
                setFound(null)
            }
            if(foundPre.uid !== "NO_DATA") {
                setFound(foundPre)
            }
        }

        if (selectedRange === "CURRENT_QUARTER") {
            if (ite.outcomeId && ite.outcomesResponses) {
                const withouNoData = [...ite.outcomesResponses].filter(it => it.uid !== "NO_DATA")
                setmax(Math.max(...[...withouNoData.map(it => it.avg)]))
            } else if (ite.type !== "TYPE3" && ite.influenceResponses) {
                const withouNoData = [...ite.influenceResponses].filter(it => it.uid !== "NO_DATA")
                setmax(Math.max(...[...withouNoData.map(it => it.avg)]))
            }

            setValue(foundPre?.avg)
        } else if (selectedRange === "CURRENT_YEAR") {
            if (ite.outcomeId && ite.outcomesResponses) {
                const withouNoData = [...ite.outcomesResponses].filter(it => it.uid !== "NO_DATA")
                setmax(Math.max(...[...withouNoData.map(it => Number(it.avg))]))
            } else if (ite.type !== "TYPE3" && ite.influenceResponses) {
                const withouNoData = [...ite.influenceResponses].filter(it => it.uid !== "NO_DATA")
                setmax(Math.max(...[...withouNoData.map(it => Number(it.avg))]))
            }
            if(ite.influenceId === "vTkNdfUbhZTH986CZed9qf") {
                console.log("foundPre max ?????", foundPre);
            }
            setValue(Number(foundPre?.avg))
        } else {
            if ((ite.outcomeId) && ite.outcomesResponses) {
                const withouNoData = [...ite.outcomesResponses].filter(it => it.uid !== "NO_DATA")
                setmax(Math.max(...[...withouNoData.map(it => it.index)]))
            } else if (ite.type === "TYPE3" && ite.influenceResponses) {
                const withouNoData = [...ite.influenceResponses].filter(it => it.uid !== "NO_DATA")
                setmax(Math.max(...[...withouNoData.map(it => it.index)]))
            } else if (ite.type !== "TYPE3" && ite.influenceResponses) {
                const withouNoData = [...ite.influenceResponses].filter(it => it.uid !== "NO_DATA")
                setmax(Math.max(...[...withouNoData.map(it => it.index)]))
            }
            if(ite.influenceId === "vTkNdfUbhZTH986CZed9qf") {
                console.log("foundPre max ?????++++++", item, foundPre);
            }
            if (foundPre?.index === "NO_DATA" || foundPre?.uid === "NO_DATA") {
                setValue(null)
            } else if (ite.outcomeId || ite.type === "TYPE3") {
                setValue(foundPre?.index)
            } else if (ite.type !== "TYPE3") {
                setValue(foundPre?.score)
            }
        }
    }, [ite, selectedRange])
    if(ite.influenceId === "vTkNdfUbhZTH986CZed9qf") {
        console.log("foundPre max", max, value, isDot, !isDot && !value, found && value && !["NaN"].includes(value.toString()) && Number(value) >= 0.50);
        console.log("foundPre max 1", found);
        console.log("foundPre max 2",found && value && !["NaN"].includes(value.toString()) && Number(value) >= 0.50);
    }
    const height = value * 100 / max
    return <Box sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "4px",
        flex: 1,
        height: "64px",
        alignSelf: "stretch",
        justifyContent: isDot ? "center" : "flex-end"
    }} key={mainKey}>
        {isDot ? found && (((["CURRENT_YEAR", "CURRENT_QUARTER"].includes(selectedRange) && found.dataAvailable) || !["CURRENT_YEAR", "CURRENT_QUARTER"].includes(selectedRange))) && value && !["NaN"].includes(value.toString()) && Number(value) >= 0.50 ? <Box sx={{
            width: "18px",
            height: "18px",
            borderRadius: "50%",
            background: "var(--color-new-main)"
        }}></Box> : found && (((["CURRENT_YEAR", "CURRENT_QUARTER"].includes(selectedRange) && found.dataAvailable) || !["CURRENT_YEAR", "CURRENT_QUARTER"].includes(selectedRange))) &&  !["NaN"].includes(Number(value).toString()) && Number(value) < 0.50 ? <Box sx={{
            width: "18px",
            height: "18px",
            borderRadius: "50%",
            background: "#FFA9A9"
        }}></Box> : <CommonTypo color="var(--color-text-primary)" size={12} lineHeight={12} fontWeight={400}>{"-"}</CommonTypo> : <Box sx={{
            height: "48px",
            display: "flex",
            alignItems: "flex-end"
        }}><Box sx={{
            width: "60px",
            height: height + "%",
            borderRadius: "8px",
            background: "var(--color-new-main)"
        }}></Box></Box>}

        {!isDot && !value ? <CommonTypo color="var(--color-text-primary)" size={12} lineHeight={12} fontWeight={400}>{"-"}</CommonTypo> : !isDot && <CommonTypo color="var(--color-text-primary)" size={12} lineHeight={12} fontWeight={400}>{value}</CommonTypo>}

    </Box>
}