import { Box } from "@mui/material"
import CommonTypo from "../../../components/CommonTypo"
import React from "react"

const ChartSideBox = ({ currentAvg, percentageChange, selectedOption }) => {

    const getText = () => {
        if(selectedOption === "CURRENT_QUARTER") {
            return " last quarter"
        }
        if(selectedOption === "CURRENT_YEAR") {
            return " last year"
        }
        if(selectedOption === "CURRENT_TWO_WEEKS") {
            return " last 14 days"
        }
        if(selectedOption === "CURRENT_MONTH") {
            return " last month"
        }
        if(selectedOption === "CUSTOM") {
            return ""
        }
    }
    return <Box sx={{
        width: "275px",
        height: "194px",
        padding: "16px 24px 16px 24px",
        gap: "16px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "12px",
        border: "1px solid #EEEEEE",
    }}>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px"
        }}>
            <CommonTypo size={26} color="#3E4240" fontWeight={600} >{currentAvg}</CommonTypo>
            <CommonTypo size={14} color="#3E4240" fontWeight={400} >{percentageChange + "% " + getText()}</CommonTypo>

        </Box>

        <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px"
        }}>
            <CommonTypo size={16} lineHeight={24} color="#3E4240" fontWeight={400} >{`Check the correlations to understand positive and negative influences`}</CommonTypo>
        </Box>

    </Box>
}

export default ChartSideBox