import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useMemo } from "react";
import palette from "./theme/palette";
import typography from "./theme/typography";
import componentsOverride from "./theme/overrides";
import Router from "./routes";
import { Provider as UserTypeProvider } from "./context/userStatus";
import ScrollToTop from "./utils/scrollToTop";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./app.css";
import { Provider as ReportsTypeContextProvider } from "./context/userIReports";
import { Provider as NotificationContextProvider } from "./context/userNotification";
import { Provider as ContactsProvider } from "./context/userIContacts";
import { Provider as TrackingProvider } from "./context/userTracking";
import SocialReports from "./SocialReports";
import { userService } from "./services/auth";
import packageJson from "../package.json";
import moment from "moment";
import { isUnderMaintenance } from "./services/config";
import UnderMaintenance from "./underMaintenance";

function App() {
  useEffect(() => {
    checkVersionFunction();
    userService.getIPData();
  }, []);
  const themeOptions = useMemo(
    () => ({
      palette,
      typography,
    }),
    []
  );
  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);
  const checkVersionFunction = async () => {
    window
      .fetch(`/meta.json?${new Date().getTime()}`, { cache: "no-cache" })
      .then((response) => response.json())
      .then(async (meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;
        console.log("latestVersionDate", meta);
        console.log("currentVersionDate", currentVersionDate);
        const shouldForceRefresh = await buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          refreshCacheAndReload();
        }
      });
  };
  const buildDateGreaterThan = async (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);
    console.log("momLatestDateTime", momLatestDateTime);
    console.log("momCurrentDateTime", momCurrentDateTime);

    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
      return true;
    } else {
      return false;
    }
  };
  const refreshCacheAndReload = () => {
    console.log("Clearing cache and hard reloading...", caches);
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <UserTypeProvider>
        <TrackingProvider>
          <ReportsTypeContextProvider>
            <NotificationContextProvider>
              <ContactsProvider>
                <ThemeProvider theme={theme}>
                  {isUnderMaintenance ? (
                    <UnderMaintenance />
                  ) : (
                    <>
                      <ScrollToTop />
                      <CssBaseline />
                      <Router />
                    </>
                  )}
                </ThemeProvider>
              </ContactsProvider>
            </NotificationContextProvider>
          </ReportsTypeContextProvider>
        </TrackingProvider>
      </UserTypeProvider>
    </DndProvider>
  );
}

export default App;
