import React from "react"
import { Box, Grid } from "@mui/material"
import CommonTypo from "../../../components/CommonTypo"
import insightsProgressStyles from "../insightsClasses"
import Entries from "../common/entries"
import AvgRating from "../common/avgRating"
import HighestEntryOn from "../common/highestEntryOn"

const GoalsInfluences = ({ influenceResponse, selectOutcome }) => {
    const classesV2 = insightsProgressStyles()
    console.log("influenceResponse", influenceResponse, selectOutcome);
    const weekCurrent = influenceResponse?.weekCount?.currentWeekcount * 100 / 7
    const weekPrevious = influenceResponse?.weekCount?.lastWeekCount * 100 / 7
    const { mostInflueceOutcome = [], specificInflunceData = [] } = influenceResponse
    return (
        <>
            <Box sx={{
                display: "flex",
                gap: "12px",
                flexWrap: "wrap"
            }}>
                {/* <Box sx={{
                    padding: "24px",
                    border: "1px solid #EEEEEE",
                    display: "flex",
                    borderRadius: "12px",
                    flexDirection: "column",
                    gap: "8px",
                    justifyContent: "space-between",
                    width: "276px",
                    height: "184px"
                }}>
                    <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                        whiteSpace: "nowrap",
                    }}>{"Weekly goal"}</CommonTypo>
                    <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                        whiteSpace: "nowrap",
                    }}>{"71%"}</CommonTypo>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                    }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <CommonTypo size={16} lineHeight={16} color="#656565" fontWeight={600} extraSx={{
                                whiteSpace: "nowrap",
                            }}>{"3.6 avg"}</CommonTypo>
                            <CommonTypo size={16} lineHeight={16} color="#656565" fontWeight={400} extraSx={{
                                whiteSpace: "nowrap",
                            }}>{"4.1 avg"}</CommonTypo>
                        </Box>
                        <Progress achive={70} />
                    </Box>
                </Box> */}
                <Entries currentWeekcount={influenceResponse?.weekCount?.currentWeekcount || 0}
                    lastWeekCount={influenceResponse?.weekCount?.lastWeekCount}
                    weekCurrent={weekCurrent}
                    weekPrevious={weekPrevious} />
                {selectOutcome.type !== "TYPE3" && <AvgRating rating={influenceResponse?.avg || 0} />}
                {specificInflunceData.length > 0 && (
                    <HighestEntryOn specificInflunceData={specificInflunceData} />
                )}

                <Box sx={{
                    padding: "24px",
                    border: "1px solid #EEEEEE",
                    display: "flex",
                    borderRadius: "12px",
                    flexDirection: "column",
                    gap: "8px",
                    height: "184px",
                    width: "276px",
                    justifyContent: "space-between"
                }}>
                    <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                        whiteSpace: "nowrap",
                    }}>{"Most influence on"}</CommonTypo>
                    <Box sx={{
                        height: "68px",
                        padding: "16px",
                        borderRadius: "8px",
                        width: "228px",
                        border: "1px solid #EEEEEE"
                    }}>
                        <div className={classesV2.outcomeSelectionBoxContent}>
                            {(
                                <div className="outcome-influ-card" style={{ background: "var(--color-new-main)", width: "36px", height: "36px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24">
                                        <path d={mostInflueceOutcome[0]?.icon} fill="#fff" />
                                    </svg>
                                </div>
                            )}
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                width: "calc(100% - 100px)",
                                height: "32px",
                            }}>
                                <CommonTypo size={16} color="#3E4240" title={mostInflueceOutcome[0]?.outcome[0]?.name} lineHeight={16} fontWeight={600} extraSx={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                }}>{mostInflueceOutcome[0]?.outcome[0]?.name}</CommonTypo>
                                <CommonTypo size={12} color="#656565" lineHeight={12} fontWeight={400}>{"Current Avg."}</CommonTypo>
                            </Box>
                            <Box sx={{
                                display: "grid",
                                justifySelf: "flex-end",
                                right: 0
                            }}>
                                <CommonTypo size={26} color="#3E4240" fontWeight={500}>{mostInflueceOutcome[0]?.outcome[0]?.avg}</CommonTypo>
                            </Box>
                        </div>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default GoalsInfluences


export const Progress = ({ total = 100, achive = 0, height = 16 }) => {
    return <Box sx={{
        background: "#EEEEEE",
        width: total + "%",
        height: height + "px",
        borderRadius: "8px"
    }}>
        <Box sx={{
            background: "var(--color-new-main)",
            width: `calc(${achive}%)`,
            height: height + "px",
            borderRadius: "8px"
        }} />
    </Box>
}

export const DayRender = ({ day, rating, success }) => {
    const percentage = (rating * 100) / 7
    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "center",
        gap: "8px"
    }}>
        <Box sx={{
            background: success ? "var(--color-new-main)" : "#EEEEEE",
            borderRadius: "8px",
            width: "59.86px",
            height: `calc(${percentage}%)`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingBottom: "4px"
        }}>
            <CommonTypo size={16} color="#656565" fontWeight={success ? 600 : 400}>{rating}</CommonTypo>
        </Box>
        <CommonTypo size={16} color="#000000" fontWeight={success ? 600 : 400}>{day}</CommonTypo>
    </Box>
}