import { Typography } from "@material-ui/core";
import {
  Autocomplete,
  Grid,
  MenuItem,
  Select,
  TextField,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import SideBar from "../../components/SideBar";
import { UserTypeContext } from "../../context/userStatus";
import FullpageLoader from "../../components/fullPageLoader";
import SnackBarComp from "../../components/snackBar";
import { userService } from "../../services/auth";
import useWidth from "../../utils/useWidth";
import { baseEndpoints } from "../../services/config";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addOutcomePopup } from "../../store/actions/userTypeAction";
import { SettingSidebar } from "../settings";
import moment from "moment";
import { COUNTRIES } from "../../utils/countryList";
import SuccessIcon from "../../assets/images/icon/SuccuessIcon";
import NewButton from "../../components/NewButton";
const DummyUser = require("../../assets/images/userDummy.png");

const useStyles = makeStyles((theme) => ({
  try: {
    color: "black",
    marginTop: "5px",
    fontWeight: 600,
  },
  subHeading: {
    color: `${palette.secondary.contrast}!important`,
    fontSize: "16px",
    fontWeight: 400,
  },
  countryCodeBox: {
    padding: "0px",
    borderRadius: "12px",
    border: "1px solid #EDEDED",
    backgroundColor: "#FFFFFF",
    minWidth: "100%",
    outline: "none",
    marginBottom: `0px !important`,
    "& .MuiTextField-root": {
      marginBottom: `0px !important`,
      borderRadius: "12px",
      "& .MuiOutlinedInput-root": {
        // padding: '0px'

        borderRadius: "12px !important",
      },
      "& .MuiOutlinedInput-input": {
        padding: "11px 13px !important",
        fontSize: "12px !important",
        fontWeight: `500 !important`,
        // padding: '7px 13px 0px!important'
      },
    },

    "& .MuiAutocomplete-option": {
      fontSize: "12px",
    },
  },
  container: {
    // backgroundColor: "white",
    // width: "91%",
    borderLeft: "1px solid #DCE6E0",
    padding: "24px",
    backgroundColor: "#F7F9FA !important",
    height: "calc(100vh - 10px)",
  },
  containerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "20px",
    height: "100%",
    overflowY: "auto",
  },
  table: {
    marginTop: "50px",
  },
  main: {
    display: "flex",
  },
  activeTab: {
    background: "#11734A",
    color: "#fff",
    borderRadius: "8px",
  },
  conatinerSetting: {
    display: "flex",
    width: "100%",
    gap: "4px",
    justifyContent: "flex-start",
  },
  settingHeader: {
    boxSizing: "border-box",

    /* Auto layout */

    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "24px",
    gap: "10px",
    width: "100%",
    background: "#FFFFFF",
    borderBottom: "1px solid #E1E1E1",
  },
  settingHeadertext: {
    // fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "100%",
    /* identical to box height, or 28px */

    color: "#000000",
  },
  imageDiv: {
    boxSizing: "border-box",

    /* Auto layout */

    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px",
    gap: "10px",
    width: "100%",
    background: "#FFFFFF",
    borderBottom: "1px solid #E1E1E1",
  },
  imageDiv1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "16px",
    width: "100%",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "12px",
  },
  uploadButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    height: "38px",

    background: "var(--color-new-main)",
    borderRadius: "8px",
    cursor: "pointer",
  },
  uploadButtonText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "100%",
    /* identical to box height, or 14px */

    display: "flex",
    alignItems: "center",
    textAlign: "center",

    color: "var(--color-black)",
  },
  removeButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",

    height: "38px",

    background: "#FFFFFF",
    border: "1px solid #F3F3F3",
    borderRadius: "8px",
    cursor: "pointer",
  },
  removeButtonText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "100%",
    /* identical to box height, or 14px */

    display: "flex",
    alignItems: "center",
    textAlign: "center",

    color: "#000000",
  },
  dataDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px",
    gap: "24px",
    width: "100%",
  },
  dataDivTitle: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "100%",
    /* identical to box height, or 24px */

    color: "#000000",
  },
  inputLists: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "24px",
    width: "100%",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "16px",
    width: "100%",
  },
  input: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "12px",
    width: "100%",
  },
}));

function ProfileComp() {
  const classes = useStyles();
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [UserData, setUserData] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Country, setCountry] = useState(null);
  const [DOB, setDOB] = useState(null);
  const [Email, setEmail] = useState("");
  const [ImageFile, setImageFile] = useState(null);
  const [Uploaded, setUploaded] = useState(false);
  const [isExpandMode, setIsExpandMode] = useState(
    localStorage.getItem("isExpandMode") === "true"
  );
  const [showLoader, setShowLoader] = useState(true);

  let userValues = JSON.parse(localStorage.getItem("userValues"));

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const getUserDetails = () => {
    const userId = {
      uid: outcomeState?.userId
        ? outcomeState?.userId
        : userValues?.uid
        ? userValues?.uid
        : localStorage.getItem("userId"),
    };
    userService
      .getUserById(userId)
      .then((data: any) => {
        setUserData(data?.data);
        setFirstName(data?.data.first_name);
        setLastName(data?.data.last_name);
        setEmail(data?.data.user_email);
        setDOB(data?.data?.dob);
        if (data?.data?.country && data?.data?.country !== "Not Updated") {
          setCountry(data?.data?.country);
        }
        data?.data?.imageUrl !== "Not Updated"
          ? setImageFile(data?.data?.imageUrl)
          : setImageFile(null);
        localStorage.setItem("userValues", JSON.stringify(data?.data));
        setShowLoader(false);
      })
      .catch((error) => {
        console.log("User profile Error");
      });
  };

  const setUserImage = () => {
    const token = localStorage.getItem("userDetails");
    var bodyFormData = new FormData();
    bodyFormData.append("photo", ImageFile);
    userService
      .setUserImage(bodyFormData, token)
      .then((data: any) => {
        setMsgType("success");
        setShowSnakbar(true);
        setSnakbarMsg(data.description);
        setShowLoader(false);
      })
      .catch((error) => {
        console.log("User profile Error");
        setMsgType("error");
        setShowSnakbar(true);
        setSnakbarMsg(error);
      });
  };

  const setUserInfo = () => {
    const token = localStorage.getItem("userDetails");

    let body = {
      first_name: FirstName,
      last_name: LastName,
      country: Country,
      dob: DOB ? moment(new Date(DOB)).format("MM-DD-YYYY") : null,
    };

    userService
      .setUserInfo(body, token)
      .then((data: any) => {
        setMsgType("success");
        setShowSnakbar(true);
        setSnakbarMsg(data.description);
        setShowLoader(false);
      })
      .catch((error) => {
        console.log("User profile Error");
        setMsgType("error");
        setShowSnakbar(true);
        setSnakbarMsg(error);
      });
  };

  const deleteProfileImage = () => {
    userService
      .deleteProfileImage()
      .then((data: any) => {
        setMsgType("success");
        setShowSnakbar(true);
        setSnakbarMsg(data.description);
        setImageFile(null);
      })
      .catch((error) => {
        setMsgType("error");
        setShowSnakbar(true);
        setSnakbarMsg(error);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const width = useWidth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  return (
    <div className={classes.main}>
      {showLoader && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <Grid
        container
        columns={16}
        // height={"100vh"}
        sx={{ /* maxHeight: '100vh', */ overflow: "hidden" }}
        className="tracking_container"
        // style={{
        //   height: '100vh !important'
        // }}
      >
        <Grid
          item
          xs={isExpandMode ? 0.73 : 2.04}
          sx={{ borderRight: "1px solid #DCE6E0" }}
        >
          <SideBar
            isExpandMode={isExpandMode}
            setIsExpandMode={setIsExpandMode}
          />
        </Grid>
        <Grid
          item
          xs={isExpandMode ? 15.27 : 13.96}
          sx={{
            flexDirection: "column",
            background: "transparent",
            maxHeight: "100vh",
          }}
          // className={classes.trackBodyGrid}
        >
          <div className={classes.conatinerSetting}>
            <Grid
              className={`${classes.container}`}
              style={{ width: isExpandMode ? "98%" : "91%", display: "flex" }}
            >
              <div
                className={
                  classes.containerDiv
                } /* style={{ width: "100%", background: 'white' }} */
              >
                <div className={classes.settingHeader}>
                  <Typography
                    variant="h4"
                    component="h4"
                    className={classes.settingHeadertext}
                  >
                    Profile settings
                  </Typography>
                </div>
                <div className={classes.imageDiv}>
                  <div className={classes.imageDiv1}>
                    <img
                      alt="user"
                      src={
                        ImageFile
                          ? Uploaded
                            ? URL.createObjectURL(ImageFile)
                            : `${baseEndpoints.getImage}${ImageFile}`
                          : DummyUser
                      }
                      style={{
                        height: 72,
                        width: 72,
                        borderRadius: "999px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "0px",
                        gap: "12px",
                      }}
                    >
                      <div className={classes.buttonGroup}>
                        <div
                          onClick={(e) => handleClick(e)}
                          className={classes.uploadButton}
                        >
                          <Typography
                            variant="h4"
                            component="h4"
                            className={classes.uploadButtonText}
                          >
                            {ImageFile ? "Change Image" : "Upload Image"}
                          </Typography>
                        </div>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                          onChange={(event) => {
                            setImageFile(event.target.files[0]);
                            setUploaded(true);
                          }}
                        />
                        {ImageFile ? (
                          <div
                            className={classes.removeButton}
                            onClick={deleteProfileImage}
                          >
                            <Typography
                              variant="h4"
                              component="h4"
                              className={classes.removeButtonText}
                            >
                              {"Remove"}
                            </Typography>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.dataDiv}>
                  <Typography
                    variant="h4"
                    component="h4"
                    className={classes.dataDivTitle}
                  >
                    Personal Data
                  </Typography>
                  <div className={classes.inputLists}>
                    <div className={classes.inputGroup}>
                      <div className={classes.input}>
                        <label
                          style={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          First Name
                        </label>
                        <input
                          style={{
                            padding: "20px",
                            borderRadius: "12px",
                            border: "1px solid #EDEDED",
                            backgroundColor: "#FFFFFF",
                            minWidth: /* width > 768 ? "360px" : */ "100%",
                            outline: "none",
                          }}
                          value={FirstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: "10px",
                            visibility: !FirstName ? "visible" : "hidden",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          First name is empty
                        </p>
                      </div>
                      <div className={classes.input}>
                        <label
                          style={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          Last Name
                        </label>
                        <input
                          style={{
                            padding: "20px",
                            borderRadius: "12px",
                            border: "1px solid #EDEDED",
                            backgroundColor: "#FFFFFF",
                            minWidth: /* width > 768 ? "360px" : */ "100%",
                            outline: "none",
                          }}
                          value={LastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: "10px",
                            visibility: !LastName ? "visible" : "hidden",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          Last name is empty
                        </p>
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <div className={classes.input}>
                        <label
                          style={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          Date of birth
                        </label>
                        <input
                          style={{
                            padding: "20px",
                            borderRadius: "12px",
                            border: "1px solid #EDEDED",
                            backgroundColor: "#FFFFFF",
                            minWidth: /* width > 768 ? "360px" : */ "100%",
                            outline: "none",
                          }}
                          type="date"
                          value={moment(new Date(DOB)).format("YYYY-MM-DD")}
                          onChange={(e) => setDOB(e.target.value)}
                          max={moment(new Date()).format("YYYY-MM-DD")}
                          min={moment("1940-01-01").format("YYYY-MM-DD")}
                        />
                      </div>
                      <div className={classes.input}>
                        <label
                          style={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          Country
                        </label>
                        {/* <select
                          style={{
                            padding: "20px",
                            borderRadius: "12px",
                            border: "1px solid #EDEDED",
                            backgroundColor: "#FFFFFF",
                            minWidth:  "100%",
                            outline: "none",
                          }}
                          placeholder="Select Country"
                          value={Country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value="" disabled>Select Country...</option>
                          {COUNTRIES.sort(function (a, b) {
                            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                            return 0;
                          }).map(it => (

                            <option value={it.name}>{it.name} {Country === it.name ? <SuccessIcon /> : ""}</option>
                          ))}
                        </select> */}
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={COUNTRIES.sort(function (a, b) {
                            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                              return -1;
                            }
                            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                              return 1;
                            }
                            return 0;
                          }).map((it) => ({
                            // ...it,
                            label: it.name,
                          }))}
                          value={Country}
                          onChange={(e: any) =>
                            setCountry(e.target.textContent)
                          }
                          sx={{}}
                          className={classes.countryCodeBox}
                          PaperComponent={({ children }) => (
                            <Paper
                              style={{ background: "#FFF" }}
                              sx={{
                                ".MuiAutocomplete-option": {
                                  color: "rgb(0, 0, 0)",
                                  fontSize: "12px",
                                },
                              }}
                            >
                              {children}
                            </Paper>
                          )}
                          disableClearable
                          placeholder="Select Country"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                marginBottom: 0,
                                ".MuiTextField-root": {
                                  marginBottom: 0,
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <div className={classes.input}>
                        <label
                          style={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          Email
                        </label>
                        <input
                          style={{
                            padding: "20px",
                            borderRadius: "12px",
                            border: "1px solid #EDEDED",
                            backgroundColor: "#FFFFFF",
                            minWidth: /* width > 768 ? "360px" : */ "100%",
                            outline: "none",
                          }}
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                          disabled
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: "10px",
                            visibility: !Email ? "visible" : "hidden",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          Email is empty
                        </p>
                      </div>
                      <div className={classes.input}></div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0px 24px 24px",
                  }}
                >
                  <NewButton
                    buttonText={"Save"}
                    variant="contained"
                    onClick={() => {
                      setUserInfo();
                      Uploaded && setUserImage();
                      userTypeDispatch(
                        addOutcomePopup({
                          first_name: FirstName,
                          last_name: LastName,
                        })
                      );
                      Uploaded &&
                        userTypeDispatch(
                          addOutcomePopup({
                            image: ImageFile,
                            isUploaded: true,
                            first_name: FirstName,
                            last_name: LastName,
                          })
                        );
                    }}
                    isDisabled={FirstName.length === 0 || LastName.length === 0}
                  />
                </div>
              </div>
            </Grid>
            <SettingSidebar fromProfile={true} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProfileComp;
