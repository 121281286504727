import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Paper,
  Rating,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import palette from "../../theme/palette";
import typography, { pxToRem } from "../../theme/typography";
import { Card, Chip, makeStyles } from "@material-ui/core";

import ArrowNextIcon from "../../assets/images/icon/arrow_next";
import { InsightTypeContext } from "../../context/userInsights";
import { insightsService } from "../../services/insightsService";
import { UserTypeContext } from "../../context/userStatus";
import NewButton from "../../components/NewButton";
import ArrowDownNew, { ArrowUpNew } from "../../assets/images/icon/ArrowDownGreenNew";
import ArrowUp from "../../assets/images/icon/arrow_up";
import CommonTypo from "../../components/CommonTypo";
import { ChartForPositiveAndNegative } from "./influence/PositiveNegativeInfluence";
import Star from "../../assets/images/icon/Star";
import { DayRender, Progress } from "./influence/Goals";
import HighestEntryOn from "./common/highestEntryOn";
import Entries from "./common/entries";
const useStyles = makeStyles((theme) => ({
  mainTitleFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  insightsTable: {
    display: "table",
    width: "100%",
  },
  insightsTableRow: {
    display: "table-row",
  },
  insightsRegression: {
    display: "table-cell",
    width: "75px",
    verticalAlign: "middle",
    padding: "5px 0",
  },
  insightsRegressionCell: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-end",
    background: '#F17F7933',
    borderRadius: "8px 0px 0px 8px",
  },
  insightsRegressionCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-end",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsInfluences: {
    display: "table-cell",
    width: "50%",
    padding: "5px 0",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    alignItems: "center",
    textAlign: "center",
    color: "#3E4240",
    verticalAlign: "middle",
  },
  insightsInfluencesTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "center",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsCorrelation: {
    display: "table-cell",
    width: "62px",
    verticalAlign: "middle",
    padding: "5px 0",
  },
  insightsCorrelationCell: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
    background: '#ADE8884D',
    borderRadius: "0px 8px 8px 0px",

  },
  insightsCorrelationCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsRegressionPositive: {
    height: "5px",

    /* GraphicColors/DarkSky */

    background: "#4CB3FD",
    borderRadius: "8px 0px 0px 8px",
    marginLeft: "20px",
    width: "100%",
  },
  insightsRegressionPositiveText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#4CB3FD",
  },
  insightsRegressionNegative: {
    height: "10px",

    /* GraphicColors/DarkSky */

    background: '#F17F79',
    borderRadius: "8px 0px 0px 8px",
    marginLeft: "20px",
    width: "100%",
  },
  insightsRegressionNegativeText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#7572FC",
  },
  insightsCorrelationPositive: {
    height: "5px",

    /* GraphicColors/DarkSky */

    background: "#169C63",
    borderRadius: "0px 8px 8px 0px",
    marginRight: "20px",
    width: "100%",
  },
  insightsCorrelationNegative: {
    height: "10px",

    /* GraphicColors/DarkSky */

    background: '#ADE888',
    borderRadius: "0px 8px 8px 0px",
    width: "100%",
  },
  insightsCorrelationPositiveText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#169C63",
  },
  insightsCorrelationNegativeText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#F17F79",
  },
  dropDownDiv: {
    display: "flex",
  },
}));
const ArrowIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1090_701)">
    <path d="M13.825 6.6665L10 10.4832L6.175 6.6665L5 7.8415L10 12.8415L15 7.8415L13.825 6.6665Z" fill="#656565" />
  </g>
  <defs>
    <clipPath id="clip0_1090_701">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</svg>
const CorrelationsInsights = ({ selectOutcome, outcomeData }) => {
  const classes = useStyles();
  const { state: InsightState, dispatch: insightTypeDispatch } =
    useContext(InsightTypeContext);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [open, setOpen] = useState(false);
  const [openTow, setOpenTow] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [searchOnLeft, setSearchOnLeft] = useState(null);
  const [leftSideMenu, setLeftSideMenu] = useState(InsightState.leftMenu);
  const [message, setMessage] = useState(null);
  const [fullResponse, setFullResponse] = useState<any>({});
  const [selectedLeft, setSelectedLeft] = useState(null);
  const [correlationData, setCorrelationData] = useState(null);
  const [correlationStar, setCorrelationStar] = useState(0);
  const [selectedRight, setSelectedRight] = useState(null);
  const [selectedLeftEleemnt, setSelectedLeftEleemnt] = useState(null);
  const [selectedRightEleemnt, setSelectedRightEleemnt] = useState(null);
  const influenceData = [];
  if (selectOutcome) {
    const found = outcomeData.find((it) => it.outcomeId === selectOutcome);
    if (found && found.influence) {
      if (found.influence && found.influence.length) {
        found.influence.map((it) => {
          influenceData.push({
            regression: it?.regression?.slope,
            influences: it?.name,
            correlation: it?.correlation,
          });
          return it;
        });
      }
    }
  }

  const getCorrelations = () => {
    setLoadingResponse(true)
    const userId = {
      from: "10-08-2021",
      to: "10-08-2023",
      idOne: selectedLeft.outcomeId || selectedLeft.influenceId,
      idSecond: selectedRight.outcomeId || selectedRight.influenceId,
      typeOne: selectedLeftEleemnt.isOutcome ? "OUTCOME" : "INFLUENCE" +
        selectedLeftEleemnt.type +
        (selectedLeftEleemnt.inverse === "true" ? "INVERSE" : ""),
      typeTwo: selectedRightEleemnt.isOutcome
        ? "OUTCOME"
        : "INFLUENCE" +
        selectedRightEleemnt.type +
        (selectedRightEleemnt.inverse === "true" ? "INVERSE" : ""),
    };
    insightsService
      .getCorrelations(userId, IUserTypeState.token)
      .then((data: any) => {
        setFullResponse(data)
        setCorrelationData(data?.data);
        setCorrelationStar(data?.star);
        setMessage(data.message)
        setLoadingResponse(false)
      })
      .catch((error) => {
        console.log("User profile Error", error);
        setLoadingResponse(false)
      });
  };
  const setEls = () => {
    if (selectedLeft && selectedRight) {
      const allArray = [...InsightState.rightMenu];
      if (allArray && allArray.length) {
        const leftEl = allArray.filter(it => {
          return it.allIds.includes(selectedLeft.outcomeId || selectedLeft.influenceId)

        })
        if (leftEl && leftEl[0]) {
          if (selectedLeft.outcomeId) {
            const leftArray = leftEl[0].alls.filter(
              (it) => it.outcomeId === selectedLeft.outcomeId
            );
            if (leftArray && leftArray.length) {
              setSelectedLeftEleemnt({
                ...leftArray[0],
                isOutcome: true
              });
            }
          }
          if (selectedLeft.influenceId) {
            const leftArrayInflue = leftEl[0].alls.filter(
              (it) => it.influenceId === selectedLeft.influenceId
            );
            if (leftArrayInflue && leftArrayInflue.length) {
              setSelectedLeftEleemnt({
                ...leftArrayInflue[0],
                isOutcome: false
              });
            }
          }
        }
        const rightEl = allArray.filter(it => {
          return it.allIds.includes(selectedRight.outcomeId || selectedRight.influenceId)

        })
        if (rightEl && rightEl[0]) {
          if (selectedRight.outcomeId) {
            const rightArray = rightEl[0].alls.filter(
              (it) => it.outcomeId === selectedRight.outcomeId
            );
            if (rightArray && rightArray.length) {
              setSelectedRightEleemnt({
                ...rightArray[0],
                isOutcome: true,
              });
            }
          }
          if (selectedRight.influenceId) {
            const rightArrayInflue = rightEl[0].alls.filter(
              (it) => it.influenceId === selectedRight.influenceId
            );
            if (rightArrayInflue && rightArrayInflue.length) {
              setSelectedRightEleemnt({
                ...rightArrayInflue[0],
                isOutcome: false
              });
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    setEls()
  }, [selectedLeft, selectedRight]);

  useEffect(() => {
    if (selectedLeftEleemnt && selectedRightEleemnt) {
      getCorrelations();
    }
  }, [selectedLeftEleemnt, selectedRightEleemnt]);
  const defaultProps = {
    options: InsightState.leftMenu && InsightState.leftMenu.length ? InsightState.leftMenu.filter(it => it.name).sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    }).flatMap(category =>
      category.alls.map(child => ({
        category: category.name,
        ...child,
        disabled: selectedRight && selectedRight.outcomeId && child.outcomeId ? selectedRight.outcomeId === child.outcomeId : selectedRight && selectedRight.influenceId && child.influenceId ? selectedRight.influenceId === child.influenceId : false
      }))
    ) : [],
    getOptionLabel: (option) => option.name,
  };
  const defaultPropsRight = {
    options: InsightState.rightMenu.filter(it => it.name).sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    }).flatMap(category =>
      category.alls.map(child => ({
        category: category.name,
        ...child,
        disabled: selectedLeft && selectedLeft.outcomeId && child.outcomeId ? selectedLeft.outcomeId === child.outcomeId : selectedLeft && selectedLeft.influenceId && child.influenceId ? selectedLeft.influenceId === child.influenceId : false
      }))
    ),
    getOptionLabel: (option) => option.name,
  };
  const stars = [];
  for (let index = 0; index < fullResponse.star; index++) {
    stars.push(
      <>
        <Star color={"#F9D412"} size={16} />
      </>
    );
  }
  const mostEntry = [{
    day: "Mon",
    achive: 20,
    rating: 2.1
  }, {
    day: "Tue",
    achive: 20,
    rating: 4.1
  }, {
    day: "Wed",
    achive: 20,
    rating: 3.3
  }, {
    day: "Thu",
    achive: 20,
    rating: 2.0
  }, {
    day: "Fri",
    achive: 20,
    rating: 2.5
  }, {
    day: "Sat",
    achive: 20,
    rating: 1.9
  }, {
    day: "Sun",
    achive: 20,
    rating: 4.2
  }]
  const mostEntry2 = [{
    day: "Mon",
    achive: 20,
    rating: 2.1
  }, {
    day: "Tue",
    achive: 20,
    rating: 4.1
  }, {
    day: "Wed",
    achive: 20,
    rating: 3.3
  }, {
    day: "Thu",
    achive: 20,
    rating: 2.0
  }, {
    day: "Fri",
    achive: 20,
    rating: 2.5
  }, {
    day: "Sat",
    achive: 20,
    rating: 1.9
  }, {
    day: "Sun",
    achive: 20,
    rating: 4.2
  }]
  console.log("fullResponse", fullResponse);
  let successCount = Math.max(...([...mostEntry].map(it => it.rating)))
  const { separateData = {} } = fullResponse

  const maxAchive = Math.max(fullResponse?.currentWeekCount, fullResponse?.previousWeekCount)
  const weekCurrent = separateData?.currentWeekCountOverallData1 * 100 / 7
  const weekPrevious = separateData?.previousWeekCountOverallData1 * 100 / 7
  const weekCurrent2 = separateData?.currentWeekCountOverallData2 * 100 / 7
  const weekPrevious2 = separateData?.previousWeekCountOverallData2 * 100 / 7
  const { mostInflueceOutcome = [], highestDays = [], daysCount = 0 } = fullResponse
  
  if(separateData && separateData.highestDaysArrayOverallData1) {
    for (let index = 0; index < separateData.highestDaysArrayOverallData1.length; index++) {
      const element = separateData.highestDaysArrayOverallData1[index];
      if (element._id && element._id.day && element._id.day.slice(0, 3)) {
        const found = mostEntry.findIndex(it => it.day.toLowerCase() === element._id.day.slice(0, 3).toLowerCase())
        if (found > -1) {
          mostEntry[found].rating = element.count
        }
      }
    }
  }
  if(separateData && separateData.highestDaysArrayOverallData2) {
    for (let index = 0; index < separateData.highestDaysArrayOverallData2.length; index++) {
      const element = separateData.highestDaysArrayOverallData2[index];
      if (element._id && element._id.day && element._id.day.slice(0, 3)) {
        const found = mostEntry2.findIndex(it => it.day.toLowerCase() === element._id.day.slice(0, 3).toLowerCase())
        if (found > -1) {
          mostEntry2[found].rating = element.count
        }
      }
    }
  }
  

 
  return (
    <>
      <Box sx={{ flexGrow: 1, borderBottom: '1px solid #eeeeee' }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 0px 32px 0px",
          gap: "32px"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px"
          }}>
            <CommonTypo size={24} color="#3E4240" fontWeight={700} lineHeight={24} extraSx={{
              whiteSpace: "nowrap",
            }}>{"Comparison"}</CommonTypo>
            <CommonTypo size={16} color="#656565" fontWeight={400} extraSx={{
            }}>{"Explore how different metrics are connected. See how changes in one may influence the other"}</CommonTypo>
          </Box>

        </Box>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          gap: "24px",
          padding: "0px 0px 32px 0px",
        }}>
          <Autocomplete
            className="fillTextInput weekSelect"
            fullWidth
            value={selectedLeft}
            sx={{
              position: "relative",
              "& .MuiOutlinedInput-root": {
                padding: 0,
              },
            }}
            renderGroup={(params) => {
              return (
                <li key={params.key}>
                  <Box sx={{ padding: '10px 10px' }}>
                    <Typography variant="subtitle1" sx={{
                      color: palette.primary.light,
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "120% !important",
                      cursor: "pointer",
                      // textAlign: "center !important",
                      fontWeight: `700 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}>{params.group}</Typography>
                  </Box>
                  <Box sx={{ padding: '0 0px' }}>
                    {params.children}
                  </Box>
                </li>
              )
            }}
            groupBy={(option) => option.category}
            getOptionDisabled={(option) => option.disabled}
            autoComplete
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            popupIcon={<ArrowIcon />}
            PaperComponent={({ children }) => (
              <Paper
                style={{ background: "#FFF" }}
                sx={{
                  "&.MuiAutocomplete-option": {
                    color: "rgb(0, 0, 0)",
                    fontSize: "18px",
                  },
                  "& .MuiAutocomplete-listbox": {
                    padding: "0px",
                    "& li": {
                      padding: `10px`,
                      color: "#5E5F5E !important",
                      fontSize: "16px !important",
                      fontWeight: "400 !important",
                      lineHeight: "24px",
                    },
                  },
                }}
              >
                {children}
              </Paper>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  border: selectedLeftEleemnt && selectedRightEleemnt && !loadingResponse ?
                    correlationData >= 0 ? "2px solid #ADE888" : "2px solid #FFA9A9"
                    : "2px solid #EEEEEE",
                  borderRadius: "8px",
                  "&.MuiOutlinedInput-root": {
                    padding: 0,

                  },
                  "& .MuiOutlinedInput-root": {
                    background: "#FFF",
                    border: "none"
                  }
                }}
                className="fillTextInput weekSelect"
                label="Select one"
              />
            )}
            onChange={(e, newInputValue) => {
              console.log("newInputValue", newInputValue);
              setSelectedLeft(newInputValue);
            }}
            {...defaultProps}
          />
          <CommonTypo fontWeight={500} size={16} color="#3E4240">and</CommonTypo>
          <Autocomplete
            className="fillTextInput weekSelect"
            fullWidth
            value={selectedRight}
            sx={{
              position: "relative",
              "& .MuiOutlinedInput-root": {
                padding: 0,
              },
            }}
            getOptionDisabled={(option) => option.disabled}
            renderGroup={(params) => {
              return (
                <li key={params.key}>
                  <Box sx={{ padding: '10px 10px' }}>
                    <Typography variant="subtitle1" sx={{
                      color: palette.primary.light,
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "120% !important",
                      cursor: "pointer",
                      // textAlign: "center !important",
                      fontWeight: `700 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}>{params.group}</Typography>
                  </Box>
                  <Box sx={{ padding: '0 0px' }}>
                    {params.children}
                  </Box>
                </li>
              )
            }}
            groupBy={(option) => option.category}
            autoComplete
            onOpen={() => setOpenTow(true)}
            onClose={() => setOpenTow(false)}
            popupIcon={<ArrowIcon />}
            PaperComponent={({ children }) => (
              <Paper
                style={{ background: "#FFF" }}
                sx={{
                  "&.MuiAutocomplete-option": {
                    color: "rgb(0, 0, 0)",
                    fontSize: "18px",
                  },
                  "& .MuiAutocomplete-listbox": {
                    padding: "0px",
                    "& li": {
                      padding: `10px`,
                      color: "#5E5F5E !important",
                      fontSize: "16px !important",
                      fontWeight: "400 !important",
                      lineHeight: "24px",
                    },
                  },
                }}
              >
                {children}
              </Paper>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  border: "2px solid #EEEEEE",
                  borderRadius: "8px",
                  "&.MuiOutlinedInput-root": {
                    padding: 0,

                  },
                  "& .MuiOutlinedInput-root": {
                    background: "#FFF",
                    border: "none"
                  }
                }}
                className="fillTextInput weekSelect"
                label="Select one"
              />
            )}
            onChange={(e, newInputValue) => {
              setSelectedRight(newInputValue);
            }}
            {...defaultPropsRight}
          />
        </Box>
      </Box>
      {
        selectedLeftEleemnt && selectedRightEleemnt && !loadingResponse && (
          <Grid>
            {daysCount < 14 ? (
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px"
              }}>
                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  gap: "8px"
                }}>
                  <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                    whiteSpace: "nowrap",
                  }}>{"There’s no enough data yet..."}</CommonTypo>
                  <CommonTypo size={16} lineHeight={16} color="#3E4240" fontWeight={400} extraSx={{
                    whiteSpace: "nowrap",
                  }}>{"Please continue tracking and entering your activities for more accurate results and insights"}</CommonTypo>
                </Box>
                <Box sx={{
                  width: "100%",
                  height: "184px",
                  padding: "24px",
                  borderRadius: "12px",
                  border: "1px solid #EEEEEE",
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column"
                }}>
                  <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                    whiteSpace: "nowrap",
                  }}>{selectedLeftEleemnt.name + " & " + selectedRightEleemnt.name}</CommonTypo>
                  <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                    whiteSpace: "nowrap",
                  }}>{(daysCount * 100 / 14).toFixed(2) + "%"}</CommonTypo>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px"
                  }}>
                    <Box sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "8px"
                    }}>
                      <CommonTypo size={16} lineHeight={16} color="#656565" fontWeight={600} extraSx={{
                        whiteSpace: "nowrap",
                      }}>{daysCount + " times"}</CommonTypo>
                      <CommonTypo size={16} lineHeight={16} color="#656565" fontWeight={600} extraSx={{
                        whiteSpace: "nowrap",
                      }}>{"14 times"}</CommonTypo>
                    </Box>
                    <Progress achive={daysCount * 100 / 14} total={100} height={8} />
                  </Box>
                </Box>
              </Box>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "0px",
                  gap: "16px",
                  width: "100%",
                  flex: "none",
                  order: 1,
                  alignSelf: "stretch",
                  flexGrow: 0,
                }}
              >
                <div
                  style={{
                    boxSizing: "border-box",

                    /* Auto layout */

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: "0px",
                    gap: "16px",

                    width: "100%",

                    borderRadius: "12px",

                    /* Inside auto layout */

                    flex: "none",
                    order: 0,
                    alignSelf: "stretch",
                    flexGrow: 0,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      padding: "0px",
                      gap: "16px",
                      flex: "none",
                      order: 0,
                      flexGrow: 0,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px",
                        gap: "16px",

                        width: "100%",
                      }}
                    >
                      {correlationStar > 0 ? (
                        <div
                          style={{
                            /* Auto layout */

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "0px",
                            // width: '50%',
                            gap: "32px",
                            /* Inside auto layout */

                            order: 1,
                            flexGrow: 0,
                          }}
                        >
                          <Box sx={{
                            display: "flex",
                            gap: "16px",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eeeeee"
                          }}>
                            <ChartForPositiveAndNegative positive={correlationData >= 0 ? true : false} data1={fullResponse?.data1} name1={selectedLeftEleemnt.name} name2={selectedRightEleemnt.name} data2={fullResponse?.data2} isFull />
                            <Box sx={{
                              display: "flex",
                              gap: "8px",
                              flexDirection: "column",
                            }}>
                              <Box sx={{
                                padding: "16px 24px",
                                display: "flex",
                                gap: "16px",
                                flexDirection: "column",
                                borderRadius: "12px",
                                border: "1px solid #EEEEEE",
                              }}>
                                <CommonTypo fontWeight={700} size={24} lineHeight={24}>Insight</CommonTypo>
                                <CommonTypo fontWeight={400} size={16} lineHeight={24} color="#656565">{selectedLeftEleemnt.name} is strongly correlated with reduced {selectedRightEleemnt.name}, enhancing your overall well-being.</CommonTypo>
                              </Box>
                              <Box sx={{
                                display: "flex",
                                gap: "8px",
                                flexDirection: "column",
                              }}>
                                <Box sx={{
                                  padding: "14px 16px",
                                  display: "flex",
                                  gap: "4px",
                                  borderRadius: "12px",
                                  border: "1px solid #EEEEEE",
                                  alignItems: "center",
                                  justifyContent: "space-between"
                                }}>
                                  <CommonTypo fontWeight={400} size={14} lineHeight={14} color="#3E4240">Correlation</CommonTypo>
                                  <CommonTypo fontWeight={500} size={16} lineHeight={16} color="#3E4240">{correlationData}</CommonTypo>
                                </Box>
                                <Box sx={{
                                  padding: "14px 16px",
                                  display: "flex",
                                  gap: "4px",
                                  borderRadius: "12px",
                                  border: "1px solid #EEEEEE",
                                  alignItems: "center",
                                  justifyContent: "space-between"
                                }}>
                                  <CommonTypo fontWeight={400} size={14} lineHeight={14} color="#3E4240">Significance</CommonTypo>
                                  <Box sx={{
                                    display: "flex",
                                    gap: "4px",
                                    alignItems: "center",
                                  }}>
                                    {stars}
                                    <CommonTypo fontWeight={500} size={16} lineHeight={16} color="#3E4240">{fullResponse.star}</CommonTypo>
                                  </Box>
                                </Box>
                                <Box sx={{
                                  padding: "14px 16px",
                                  display: "flex",
                                  gap: "4px",
                                  borderRadius: "12px",
                                  border: "1px solid #EEEEEE",
                                  alignItems: "center",
                                  justifyContent: "space-between"
                                }}>
                                  <CommonTypo fontWeight={400} size={14} lineHeight={14} color="#3E4240">Days tracked both</CommonTypo>
                                  <CommonTypo fontWeight={500} size={16} lineHeight={16} color="#3E4240">{fullResponse.data1.length} days</CommonTypo>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={{
                            display: "flex",
                            gap: "32px",
                            flexDirection: "column"
                          }}>
                            <CommonTypo fontWeight={700} size={24} lineHeight={24}>{selectedLeftEleemnt.name} Data</CommonTypo>
                            <Box sx={{
                              display: "flex",
                              gap: "12px",
                              flexWrap: "wrap"
                            }}>
                              <Entries currentWeekcount={separateData?.currentWeekCountOverallData1 || 0}
                              lastWeekCount={separateData?.previousWeekCountOverallData1}
                              weekCurrent={weekCurrent}
                              weekPrevious={weekPrevious} />
                              {highestDays.length > 0 && (
                                // <Box sx={{
                                //   padding: "24px",
                                //   border: "1px solid #EEEEEE",
                                //   display: "flex",
                                //   borderRadius: "12px",
                                //   flexDirection: "column",
                                //   gap: "8px",
                                //   height: "184px",
                                //   width: "563px",
                                //   justifyContent: "space-between"
                                // }}>
                                //   <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                                //     whiteSpace: "nowrap",
                                //   }}>{"Highest entries on"}</CommonTypo>
                                //   <Box sx={{
                                //     display: "flex",
                                //     alignItems: "flex-end",
                                //     gap: "16px",
                                //     height: "100%"
                                //   }}>
                                //     {mostEntry.map(it => <DayRender rating={it.rating} day={it.day} success={it.rating >= successCount} />)}
                                //   </Box>
                                // </Box>
                                <HighestEntryOn specificInflunceData={separateData.highestDaysArrayOverallData1} />
                              )}
                            </Box>
                            
                          </Box>
                          <Box sx={{
                            display: "flex",
                            gap: "32px",
                            flexDirection: "column"
                          }}>
                            <CommonTypo fontWeight={700} size={24} lineHeight={24}>{selectedRightEleemnt.name} Data</CommonTypo>
                            <Box sx={{
                              display: "flex",
                              gap: "12px",
                              flexWrap: "wrap"
                            }}>
                              <Entries currentWeekcount={separateData?.currentWeekCountOverallData2 || 0}
                              lastWeekCount={separateData?.previousWeekCountOverallData2}
                              weekCurrent={weekCurrent2}
                              weekPrevious={weekPrevious2} />
                              {highestDays.length > 0 && (
                                // <Box sx={{
                                //   padding: "24px",
                                //   border: "1px solid #EEEEEE",
                                //   display: "flex",
                                //   borderRadius: "12px",
                                //   flexDirection: "column",
                                //   gap: "8px",
                                //   height: "184px",
                                //   width: "563px",
                                //   justifyContent: "space-between"
                                // }}>
                                //   <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                                //     whiteSpace: "nowrap",
                                //   }}>{"Highest entries on"}</CommonTypo>
                                //   <Box sx={{
                                //     display: "flex",
                                //     alignItems: "flex-end",
                                //     gap: "16px",
                                //     height: "100%"
                                //   }}>
                                //     {mostEntry.map(it => <DayRender rating={it.rating} day={it.day} success={it.rating >= successCount} />)}
                                //   </Box>
                                // </Box>
                                <HighestEntryOn specificInflunceData={separateData.highestDaysArrayOverallData2} />
                              )}

                              {/* <Box sx={{
                              padding: "24px",
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              borderRadius: "12px",
                              flexDirection: "column",
                              gap: "8px",
                              height: "184px",
                              width: "276px",
                              justifyContent: "space-between"
                            }}>
                              <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                                whiteSpace: "nowrap",
                              }}>{"Most influence on"}</CommonTypo>
                              <Box sx={{
                                height: "68px",
                                padding: "16px",
                                borderRadius: "8px",
                                width: "228px",
                                border: "1px solid #EEEEEE"
                              }}>
                                <div style={{
                                  height: "36px",
                                  gap: "12px",
                                  display: "flex",
                                  alignItems: "center"
                                }}>
                                  {(
                                    <div className="outcome-influ-card" style={{ background: "var(--color-new-main)", width: "36px", height: "36px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <svg width="24" height="24" viewBox="0 0 24 24">
                                        <path d={mostInflueceOutcome[0]?.icon} fill="#fff" />
                                      </svg>
                                    </div>
                                  )}
                                  <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    width: "calc(100% - 100px)",
                                    height: "32px",
                                  }}>
                                    <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={600} extraSx={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}>{mostInflueceOutcome[0]?.outcome[0]?.name}</CommonTypo>
                                    <CommonTypo size={12} color="#656565" lineHeight={12} fontWeight={400}>{"Current Avg."}</CommonTypo>
                                  </Box>
                                  <Box sx={{
                                    display: "grid",
                                    justifySelf: "flex-end",
                                    right: 0
                                  }}>
                                    <CommonTypo size={26} color="#3E4240" fontWeight={500}>{mostInflueceOutcome[0]?.outcome[0]?.avg}</CommonTypo>
                                  </Box>
                                </div>
                              </Box>
                            </Box> */}
                            </Box>
                          </Box>
                        </div>
                      ) : (
                        <Typography
                          variant="h5"
                          letterSpacing={"0.02em"}
                          sx={{
                            marginY: "10px",
                            color: "#3E4240",
                            fontWeight: 600,
                          }}
                        >
                          {"No Correlation found"}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

          </Grid >
        )
      }
    </>
    // <div className={classes.mainTitleFlex}>
    //   <Box display={"flex"} gap={"28px"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
    //     <Box display={"flex"} gap={"8px"} flexDirection={"column"}>
    //       <Typography
    //         variant="h3"
    //         letterSpacing={"0.02em"}
    //         sx={{
    //           color: palette.primary.light,
    //           fontSize: `${pxToRem(18)} !important`,
    //           lineHeight: "100% !important",
    //           // textAlign: "center !important",
    //           fontWeight: `600 !important`,
    //           "&.MuiTypography-root": {
    //             "@media  (max-width: 600px) and (min-width: 100px)": {
    //               fontSize: `${pxToRem(18)} !important`,
    //               lineHeight: "100% !important",
    //             },
    //           },
    //         }}
    //       >
    //         Comparision
    //       </Typography>
    //       <Typography
    //         variant="h5"
    //         letterSpacing={"0.02em"}
    //         sx={{
    //           color: palette.primary.light,
    //           fontSize: `${pxToRem(16)} !important`,
    //           lineHeight: "100% !important",
    //           // textAlign: "center !important",
    //           marginBottom: 0,
    //           fontWeight: `400 !important`,
    //           "&.MuiTypography-root": {
    //             "@media  (max-width: 600px) and (min-width: 100px)": {
    //               fontSize: `${pxToRem(16)} !important`,
    //               lineHeight: "100% !important",
    //             },
    //           },
    //         }}
    //       >
    //         Select two attributes to find the correlation between them.
    //       </Typography>
    //     </Box>
    //     <div className={classes.dropDownDiv}>
    //       <Grid
    //         display="flex"
    //         alignItems="center"
    //         flexWrap="wrap"
    //       >

    //       </Grid>
    //       <Typography
    //         variant="h5"
    //         letterSpacing={"0.02em"}
    //         sx={{
    //           color: palette.common.black,
    //           margin: "auto 12px",
    //           fontSize: `${pxToRem(16)} !important`,
    //           lineHeight: "150% !important",
    //           // textAlign: "center !important",
    //           fontWeight: `600 !important`,
    //           "&.MuiTypography-root": {
    //             "@media  (max-width: 600px) and (min-width: 100px)": {
    //               fontSize: `${pxToRem(16)} !important`,
    //               lineHeight: "150% !important",
    //             },
    //           },
    //         }}
    //       >
    //         and
    //       </Typography>
    //       <Grid
    //         display="flex"
    //         alignItems="center"
    //         flexWrap="wrap"
    //         marginTop="3px"
    //       >

    //       </Grid>
    //     </div>
    //   </Box>


    // </div>
  );
};
export default CorrelationsInsights;
