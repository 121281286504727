/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import palette from "../../theme/palette";
import LeftSideImprovement from "./leftsideImprovements";
// import RightPartOutCome from "./rightOutCome";
import { outcomeService } from "../../services/outcome";
import { OutcomeGetResponse } from "../../interfaces/outcomeModal";
import { UserTypeContext } from "../../context/userStatus";
import { addOutcomeAction } from "../../store/actions/userTypeAction";
import SnackBarComp from "../../components/snackBar";
import { OutcomeStatus } from "../../utils/staticDataJson";
import FullpageLoader from "../../components/fullPageLoader";
import { onboardingPlansService } from "../../services/onboardingPlans";
import LeftSideSelectedImprovement from "./leftsideSelectedImprovements";
import LeftSideFinalImprovement from "./leftsideFinalImprovements";
import { useNavigate } from "react-router-dom";
import FinalAnimation from "./finalAnimation";
import { pxToRem } from "../../theme/typography";
import useWidth from "../../utils/useWidth";
import { userService } from "../../services/auth";
import queryParams from "../../utils/getQueryParams";
import { trackingService } from "../../services/tracking";

const AddImprovements = () => {
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const queryData = queryParams()
  const [outComeValue, setOutcomeValue] = useState("");
  const [listOfImprovements, setListOfImprovements] = useState([]);
  const [curretStep, setCurrentStep] = useState(0);
  const [addOutcomeTracking, setAddOutcomeTracking] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState([])
  const [secondselectedPlans, setSecondSelectedPlans] = useState([])
  const [planOutcomes, setPlanOutcomes] = useState([])
  const [planInfluences, setPlanInfluences] = useState([])
  const [planReflectives, setPlanReflectives] = useState([])
  const [planSelectedOutcomes, setSelectedPlanOutcomes] = useState([])
  const [planSelectedInfluences, setSelectedPlanInfluences] = useState([])
  const [planSelectedReflectives, setSelectedPlanReflectives] = useState([])
  const [planStep, setPlanStep] = useState(1);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [showOutcome, setShowOutcome] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [outcomeIdeas, setOutcomeIdeas] = useState();
  const [textInput, setTextInput] = useState(OutcomeStatus);
  const [fullpageLoader, setFullPageLoader] = useState(true);
  const [showFinalAnimation, setShowAnimation] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)
  const [dataLoading, setLoadingOfData] = useState(false)
  const [planTracked, setPlanTracked] = useState(false)
  const [outcomeTracked, setOutcomeTracked] = useState(false)
  const [influenceTracked, setInfluenceOfData] = useState(false)
  const [names, setShoName] = useState<any>([])
  const setShowNames = (name) => {
    const old = [...names];
    // old.push(name)
    setShoName([...old, name])
  }
  useEffect(() => {
    getUserDetails()
    // GET OUTCOME LIST
    onboardingPlansService
      .getPlansList(outcomeState.token)
      .then((data: any) => {
        setListOfImprovements(data.data)
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
      });
    setFullPageLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getUserDetails = () => {
    let userDetails
    if (outcomeState.userId == null && localStorage.getItem("userDetails")) {
      try {
        userDetails = JSON.parse(localStorage.getItem("userValues"));

      } catch (error) {
        userDetails = localStorage.getItem("userValues");
      }
    }
    const userId = {
      uid: outcomeState.userId
        ? outcomeState.userId
        : userDetails?.user?.uid
          ? userDetails?.user?.uid
          : userDetails?.uid ? userDetails?.uid : userDetails,
    };
    userService
      .getUserById(userId)
      .then((data: any) => {

        if (!(data?.data?.level === "NOT_FOUND")) {
          if (data?.data?.level === "LOW" && (data?.outcome || data?.influence)) {
            navigate("/tracking"  + queryData.qeryString);
          } else if (
            data?.data?.level === "HIGH" && (data?.outcome ||
              data?.influence ||
              data?.reflective)
          ) {
            navigate("/tracking"  + queryData.qeryString);
          }
        }
        localStorage.removeItem("isFromSignup")
      })
      .catch((error) => {
        localStorage.removeItem("isFromSignup")
        console.log("User profile Error");
      });
  };
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  const navigate = useNavigate();
  const handleClickStep = (step, isBack = false) => {
    setCurrentStep(step === 3 ? 2 : step)
    console.log("step", step);
    
    if (step === 2) {

      if (planStep === 1 && isBack) {
        setCurrentStep(1)
      } else if (planStep === 2 && isBack) {
        setPlanStep(1)
      } else if (planStep === 3 && isBack) {
        setPlanStep(2)
      } else {
        setPlanStep(1)
        onboardingPlansService
          .getPlanById({
            "categoryId": secondselectedPlans.map(it => it.categoryId)[0]
          }, outcomeState.token).then((data) => {
            setPlanOutcomes(data?.data[0]?.outcomes)
            setPlanInfluences(data?.data[0]?.influences)
            setPlanReflectives(data?.data[0]?.reflectives)
          })
      }

    }
    if (step === 3) {
      if (planStep === 1) {
        setPlanStep(2)
      }
      if (planStep === 2) {
        setPlanStep(3)
      }
      if (planStep === 3) {
        setButtonLoader(true)
        trackingService.signupCompletedBoarding(outcomeState.token, {
          mixpanel_type: "SIGN_UP_REFLECTIVE_C"
        });
        trackingService.signupCompletedBoarding(outcomeState.token, {
          mixpanel_type: "SIGNUP_COMPLETED"
        });
        onboardingPlansService
          .setSelectedPlan({
            "categoryIds": secondselectedPlans.map(it => it.categoryId),
            "selectedOutcomeIds": planSelectedOutcomes.map(it => it.outcomeId),
            "selectedReflectiveIds": planSelectedReflectives.map(it => it.reflectiveId),
            "selectedInfluenceIds": planSelectedInfluences.map(it => it.influenceId),
          }, outcomeState.token).then(() => {
            setLoadingOfData(true)

            localStorage.setItem("showAnimation", "true")
            setButtonLoader(false)
            navigate("/tracking/confirmation"  + queryData.qeryString);
            // navigate("/tracking", { replace: true });
          })
      }


    }
    trackEvent(step, isBack)
  }

  const trackEvent = (step, isBack = false) => {
    if(curretStep === 1 && !isBack && !planTracked) {
      setPlanTracked(true)
      trackingService.signupCompletedBoarding(outcomeState.token, {
        mixpanel_type: "SIGN_UP_PLAN_C"
      });
    }
    if(curretStep === 2 && planStep === 1 && !isBack && !outcomeTracked) {
      setOutcomeTracked(true)
      trackingService.signupCompletedBoarding(outcomeState.token, {
        mixpanel_type: "SIGN_UP_OUTCOMES_C"
      });
    }
    if(curretStep === 2 && planStep === 2 && !isBack && !influenceTracked) {
      setInfluenceOfData(true)
      trackingService.signupCompletedBoarding(outcomeState.token, {
        mixpanel_type: "SIGN_UP_INFLUENCES_C"
      });
    }
    console.log("curretStep", curretStep, planStep, step, isBack);
    
    // 
  }
  const width = useWidth()
  const isMobile = width < 768
  return (
    <>
      {fullpageLoader && <FullpageLoader />}
      <Grid
        container
        columns={16}
        className={curretStep === 0 ? ("bgImageGoalNew") : curretStep === 1 ? (isMobile ? "bgImageImproveMobile" : "bgImageImproveNew") : isMobile ? "bgImageCommonMobile" : "bgImageCommonNew"}
        sx={{ backgroundColor: palette.secondary.light, display: "flex !important" }}
      >
        <Box sx={{
          opacity: "100%",
          zIndex: 1,
          width: "100%"
        }}>
          {/* SNACK BAR */}
          <SnackBarComp
            showSnakbar={showSnakbar}
            handleCloseSnakbar={handleCloseSnakbar}
            snakbarMsg={snakbarMsg}
            type={msgType ? msgType : "info"}
          />
          {isMobile && !showFinalAnimation && <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px 30px 20px"
          }}>
            <Typography
              variant="h1"
              component="span"
              color={"#1E1E1E"}
              sx={{
                fontSize: `${pxToRem(14)} !important`,
                lineHeight: "100% !important",
                textAlign: "center !important",
                fontWeight: `400 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(14)} !important`,
                    lineHeight: "100% !important"
                  },
                }
              }}
              onClick={() => {
                if (curretStep === 0) {
                  handleClickStep(curretStep)
                } else {
                  if (curretStep === 2) {
                    handleClickStep(2, true)
                  } else {
                    handleClickStep(curretStep - 1)
                  }
                }
              }}
            >
              Go back

            </Typography>
            <Typography
              variant="h1"
              component="span"
              color={"#1E1E1E"}
              sx={{
                fontSize: `${pxToRem(14)} !important`,
                lineHeight: "100% !important",
                textAlign: "center !important",
                fontWeight: `400 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(14)} !important`,
                    lineHeight: "100% !important"
                  },
                }
              }}
            >


            </Typography>
          </Box>}
          {/* START LEFT SECTION */}
          {<Grid
            item
            sx={{
              display: 'flex',
              alignItems: isMobile && !showFinalAnimation ? 'flex-start' : "center",
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
            className="leftside-menu outcome-screen "
          >


            {curretStep === 0 && !showFinalAnimation ? (
              <LeftSideImprovement
                outComeValue={outComeValue}
                setOutcomeValue={setOutcomeValue}
                outcomeIdeas={outcomeIdeas}
                setShowOutcome={setShowOutcome}
                showOutcome={showOutcome}
                setShowSnakbar={setShowSnakbar}
                setMsgType={setMsgType}
                setSnakbarMsg={setSnakbarMsg}
                setAddOutcomeTracking={setAddOutcomeTracking}
                setTextInput={setTextInput}
                textInput={textInput}
                onboarding={true}
                listOfImprovements={listOfImprovements}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                handleClickStep={handleClickStep}
                curretStep={curretStep}
                setListOfImprovements={setListOfImprovements}
                secondselectedPlans={secondselectedPlans}
                setSecondSelectedPlans={setSecondSelectedPlans}
                setSelectedPlanOutcomes={setSelectedPlanOutcomes}
                setSelectedPlanInfluences={setSelectedPlanInfluences}
                setSelectedPlanReflectives={setSelectedPlanReflectives}
              />
            ) : curretStep === 1 && !showFinalAnimation ? (
              <LeftSideSelectedImprovement
                outComeValue={outComeValue}
                setOutcomeValue={setOutcomeValue}
                outcomeIdeas={outcomeIdeas}
                setShowOutcome={setShowOutcome}
                showOutcome={showOutcome}
                setShowSnakbar={setShowSnakbar}
                setMsgType={setMsgType}
                setSnakbarMsg={setSnakbarMsg}
                setAddOutcomeTracking={setAddOutcomeTracking}
                setTextInput={setTextInput}
                textInput={textInput}
                onboarding={true}
                listOfImprovements={listOfImprovements}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                handleClickStep={handleClickStep}
                curretStep={curretStep}
                secondselectedPlans={secondselectedPlans} setSecondSelectedPlans={setSecondSelectedPlans}
                setSelectedPlanOutcomes={setSelectedPlanOutcomes}
                setSelectedPlanInfluences={setSelectedPlanInfluences}
                setSelectedPlanReflectives={setSelectedPlanReflectives}
              />
            ) : curretStep === 2 && !showFinalAnimation ? (
              <LeftSideFinalImprovement
                outComeValue={outComeValue}
                setOutcomeValue={setOutcomeValue}
                outcomeIdeas={outcomeIdeas}
                setShowOutcome={setShowOutcome}
                showOutcome={showOutcome}
                setShowSnakbar={setShowSnakbar}
                setMsgType={setMsgType}
                setSnakbarMsg={setSnakbarMsg}
                setAddOutcomeTracking={setAddOutcomeTracking}
                setTextInput={setTextInput}
                textInput={textInput}
                onboarding={true}
                listOfImprovements={listOfImprovements}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                handleClickStep={handleClickStep}
                curretStep={curretStep}
                secondselectedPlans={secondselectedPlans} setSecondSelectedPlans={setSecondSelectedPlans}
                planOutcomes={planOutcomes}
                setPlanOutcomes={setPlanOutcomes}
                planInfluences={planInfluences}
                setPlanInfluences={setPlanInfluences}
                planReflectives={planReflectives}
                setPlanReflectives={setPlanReflectives}
                planSelectedOutcomes={planSelectedOutcomes}
                setSelectedPlanOutcomes={setSelectedPlanOutcomes}
                planSelectedInfluences={planSelectedInfluences}
                setSelectedPlanInfluences={setSelectedPlanInfluences}
                planSelectedReflectives={planSelectedReflectives}
                setSelectedPlanReflectives={setSelectedPlanReflectives}
                planStep={planStep}
                setPlanStep={setPlanStep}
                buttonLoader={buttonLoader}
              />
            ) : null}
            {showFinalAnimation && <FinalAnimation
              dataLoading={dataLoading}
            // names={names} setShowNames={setShowNames}
            // showOutcome={showOutcomeAnimation}
            // setShowOutcome={setShowOutcomeAnimation}
            // showInfluence={showInfluenceAnimation }
            // setShowInfluence={setShowInfluenceAnimation}
            // showRefelct={showRefelctAnimation}
            // setShowReflect={setShowReflectAnimation}
            />}
          </Grid>}

          {/* END LEFT SECTION */}

          {/* START RIGHT SECTION */}
          {/* <Grid
          item
          xs={6.37}
          className="rightside-menu outcome-menu outcome-right-layout"
          sx={{ backgroundColor: palette.common.bgColor }}
        >
          <RightPartOutCome
            setOutcomeValue={setOutcomeValue}
            setOutcomeIdeas={setOutcomeIdeas}
          />
        </Grid> */}
          {/* END RIGHT SECTION */}
        </Box>

      </Grid>
    </>
  );
};
export default AddImprovements;
